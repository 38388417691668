<template>
  <div class="page-content">
    <div class="search-form" style="margin-top: 10px;">
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">地区：</div>
          <div class="search-form-field-content">
            <collapse collapsed-height="36px">
              <div class="area-provinces-select">
                <div class="area-provinces-select-item" v-for="i in areaAndProvinces" :key="i.area" :style="{width:i.width}">
                  <div class="area-provinces-select-area">{{i.area}}：</div>
                  <tag-select class="area-provinces-select-provinces" :tags="i.provinces" v-model="i.selected" multiple></tag-select>
                </div>
              </div>
            </collapse>
          </div>
        </div>
      </div>
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">时间：</div>
          <div class="search-form-field-content">
            <el-date-picker style="width: 250px;margin-left: 10px;" clearable
                            v-model="searchForm.dateRange"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期" size="small" unlink-panels
                            end-placeholder="结束日期" >
            </el-date-picker>
          </div>
        </div>
        <div class="search-form-field">
          <div class="search-form-field-label">金额：</div>
          <div class="search-form-field-content v-center">
            <el-input-number v-model="searchForm.minHtMoney" style="width: 100px;" size="mini" :controls="false"></el-input-number>
            <span>~</span>
            <el-input-number v-model="searchForm.maxHtMoney" style="width: 100px;" size="mini" :controls="false"></el-input-number>
            <span>万元</span>
          </div>
        </div>
      </div>
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">合同名称：</div>
          <div class="search-form-field-content v-center">
            <el-input v-model="searchForm.htmcLike" style="width: 260px;" size="mini"></el-input>
          </div>
        </div>
        <div class="search-form-field">
          <div class="search-form-field-label">采购人：</div>
          <div class="search-form-field-content v-center">
            <el-input v-model="searchForm.partyaLike" style="width: 210px;" size="mini"></el-input>
          </div>
        </div>
        <div class="search-form-field">
          <div class="search-form-field-label">供应商：</div>
          <div class="search-form-field-content v-center">
            <el-input v-model="searchForm.partybLike" style="width: 200px;" size="mini"></el-input>
          </div>
        </div>
      </div>
      <div class="search-form-bottom">
        <el-button type="primary" @click="search">检索</el-button>
        <el-button @click="reset">重置</el-button>
      </div>
    </div>
    <div style="background-color: #FFFFFF;padding: 20px;margin-top: 25px;" v-if="data">
      <el-table :data="data.content" stripe class="large-font-table" tooltip-effect="light">
        <el-table-column prop="htmc" label="合同名称" show-overflow-tooltip>
          <a slot-scope="{row}" class="link" :href="row.url" target="_blank">{{row.htmc}}</a>
        </el-table-column>
        <el-table-column prop="partyA" label="采购人" width="250" show-overflow-tooltip></el-table-column>
        <el-table-column prop="partyB" label="供应商" width="250" show-overflow-tooltip></el-table-column>
        <el-table-column prop="signDate" label="签订时间" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="htMoney" label="合同金额(万元)" width="120" show-overflow-tooltip>
          <span slot-scope="{row}">{{row.htMoney/10000}}</span>
        </el-table-column>
      </el-table>
      <el-pagination layout="prev, pager, next" background
                     :total="data.totalElements" :current-page="data.number+1"
                     @current-change="loadData">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "../api";
import moment from "moment";
import TagSelect from "./TagSelect";
import Collapse from "./Collapse";
const DATE_FMT='YYYY-MM-DD';

export default {
  name: "ZtbList",
  components:{TagSelect,Collapse},
  data(){
    return {
      areaAndProvinces:[
        {
          area:"华东",
          provinces:["山东", "江苏", "上海", "浙江", "福建", "安徽", "江西"],
          selected:[],
          width:'40%',
        },
        {
          area:"华南",
          provinces:["广东", "广西", "海南"],
          selected:[],
          width:'30%',
        },
        {
          area:"西南",
          provinces:["四川", "云南", "重庆", "贵州", "西藏"],
          selected:[],
          width:'30%',
        },
        {
          area:"华北",
          provinces:["河北", "北京", "天津", "内蒙古", "山西"],
          selected:[],
          width:'40%',
        },
        {
          area:"西北",
          provinces:["陕西", "新疆", "甘肃", "青海", "宁夏"],
          selected:[],
          width:'30%',
        },
        {
          area:"华中",
          provinces:["湖南", "湖北", "河南"],
          selected:[],
          width:'30%',
        },
        {
          area:"东北",
          provinces:["辽宁", "吉林", "黑龙江"],
          selected:[],
          width:'40%',
        }
      ],
      searchForm:{
        dateRange:null,
        minHtMoney:undefined,
        maxHtMoney:undefined,
        htmcLike:null,
        partyaLike:null,
        partybLike:null,
      },
      searchParams:{
        area:null,
        startDate:null,
        endDate:null,
        minHtMoney:undefined,
        maxHtMoney:undefined,
        htmcLike:null,
        partyaLike:null,
        partybLike:null,
      },
      data:null
    }
  },
  created() {
    this.init()
  },
  methods:{
    async init(){
      await this.search()
    },
    async search(){
      const province=this.areaAndProvinces.reduce((acc,v)=>{
        acc.push(...v.selected)
        return acc
      },[])
      if(province.length===0){
        this.searchParams.area=null
      }else{
        this.searchParams.area=province.map(p=>{
          if(p==='北京'||p==='重庆'||p==='上海'||p==='天津'){
            return p+'市'
          }else if(p==='内蒙古'||p==='西藏'){
            return p+'自治区'
          }else if(p==='宁夏'){
            return p+'回族自治区'
          }else if(p==='广西'){
            return p+'壮族自治区'
          }else if(p==='新疆'){
            return p+'维吾尔自治区'
          }else{
            return p+'省'
          }
        })
      }
      if (this.searchForm.dateRange) {
        this.searchParams.startDate = moment(this.searchForm.dateRange[0]).format(DATE_FMT)
        this.searchParams.endDate = moment(this.searchForm.dateRange[1]).format(DATE_FMT)
      }else{
        this.searchParams.startDate = null
        this.searchParams.endDate = null
      }
      this.searchParams.minHtMoney=this.searchForm.minHtMoney?this.searchForm.minHtMoney*10000:null
      this.searchParams.maxHtMoney=this.searchForm.maxHtMoney?this.searchForm.maxHtMoney*10000:null
      this.searchParams.htmcLike=this.searchForm.htmcLike
      this.searchParams.partyaLike=this.searchForm.partyaLike
      this.searchParams.partybLike=this.searchForm.partybLike
      await this.loadData(1)
    },
    async reset(){
      this.areaAndProvinces.forEach(i=>i.selected=[])
      this.searchForm.dateRange=null
      this.searchForm.minHtMoney=undefined
      this.searchForm.maxHtMoney=undefined
      this.searchForm.htmcLike=null
      this.searchForm.partyaLike=null
      this.searchForm.partybLike=null
    },
    async loadData(page){
      this.data=(await api.listBidding({...this.searchParams,page:page-1,size:10})).data
    }
  }
}
</script>

<style scoped>

</style>
