import { render, staticRenderFns } from "./ZtbList.vue?vue&type=template&id=66b6fb77&scoped=true&"
import script from "./ZtbList.vue?vue&type=script&lang=js&"
export * from "./ZtbList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66b6fb77",
  null
  
)

export default component.exports